.accordion-menu {
    width: 100%;
}

.subFunctionality-Container {
    padding-left: 10px;
}

a {
    text-decoration: none;
    color: black;
}
.MuiAppBar-colorPrimary{
    background-color: transparent !important;
}

.MuiDrawer-paperAnchorDockedLeft {
    border: none !important;
    /* background-color:  #3f79b5; */
}
.text{
    background-color:  #3f79b5 !important;
}

.MuiTypography-body1 {
    font-size: 15px;
}

.logo-right-container {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    
}

.inner-div {
    width: 100%;
    height: 100%;
    margin-left: -5px;
    color: #fff;
}

.navbar-component {
    padding-top: 0px !important;
  height: 100%;
    /* background: #3f79b5 */
}

.navigate-select .navigate-text {
    padding: 0px !important;
    width: 200px !important;
   
}