@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);
.accordion-menu {
    width: 100%;
}

.subFunctionality-Container {
    padding-left: 10px;
}

a {
    text-decoration: none;
    color: black;
}
.MuiAppBar-colorPrimary{
    background-color: transparent !important;
}

.MuiDrawer-paperAnchorDockedLeft {
    border: none !important;
    /* background-color:  #3f79b5; */
}
.text{
    background-color:  #3f79b5 !important;
}

.MuiTypography-body1 {
    font-size: 15px;
}

.logo-right-container {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    
}

.inner-div {
    width: 100%;
    height: 100%;
    margin-left: -5px;
    color: #fff;
}

.navbar-component {
    padding-top: 0px !important;
  height: 100%;
    /* background: #3f79b5 */
}

.navigate-select .navigate-text {
    padding: 0px !important;
    width: 200px !important;
   
}
#form{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #1475cf;
    height: 300px;
    /* width: 500px; */
    cursor: pointer;
    border-radius: 5px;
  }
  
  .uploaded-row{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: #e9f0ff;
  }
  
  .upload-content{
    display: flex;
    align-items: center;
  }
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track{
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb{
  background-color: darkgrey;
}
.highlighted{
  border: 2px solid rebeccapurple !important;
  background-color: rgb(116, 11, 220) !important;
}

.suspense-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21263c;
  color: #fff;
}

.vouhcer-btn {
  border-radius: 10px !important;
  border: 1px solid blue !important;
  font-size: 15px !important;
}

.button-div {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.divider {
  background: #0000001f !important;
  height: 1 !important;
  margin: 20px 0 !important;
}

.login-divider {
  background: #0000001f !important;
  height: 1 !important;
  margin: 0px 0 !important;
}

.MuiContainer-root {
  padding: 0 !important;
}

.dateRangeDialog .MuiDialog-paperWidthSm {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

.divider {
  background: #0000001f;
  height: 1%;
  margin: 20px 0;
}

.site-paper-container {
  height: 100vh;
  width: 100%;
  background-image: url(/static/media/accounts.abc6b214.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.5s all linear;
  width: 90%;
  border: 2px solid #a7a7a7;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: translate(0px, -5px);
  cursor: pointer;
}

/* Add some padding inside the card container */
.card-container {
  text-align: center;
  background-color: white;
  color: blue;
}

.card-container>p {
  color: blue;
  cursor: pointer;
  font-size: 17px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  /* 5px rounded corners */
}

/* Add rounded corners to the top left and the top right corner of the image */
img {
  border-radius: 5px 5px 0 0;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: #3f79b5 !important;
}

.text-field {
  width: 450px !important;
  border-radius: none !important;
}

.template-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  width: 350px;
}

.template-card-container {
  text-align: center;
  color: #6c6c6c;
  padding: 10px 0;
}

.template-card-container>div {
  margin: auto;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px 14px !important;
  color: #555 !important;
}

.select-box {
  width: 250px !important;
  border-radius: none !important;
}

.text-field-transaction {
  width: 200px !important;
  border-radius: none !important;
}

.MuiAppBar-colorPrimary {
  background-color: transparent !important;
}

.text-field-remakrs {
  width: 450px !important;
  border-radius: none !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: none !important;
}

.grid-container {
  display: flex;
  align-items: flex-start;
  margin: 25px auto !important;
  align-items: center;
  padding: 0px !important;
}

.balance-div {
  height: 40px;
  width: 250px;
  margin-top: 20px;
  border-radius: 10px;
  background: #21263c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.border-top-color-cyan {
  border-top: 3px solid hsl(180, 62%, 55%);
  width: 270px !important;
}

.grid-container-account {
  display: flex;
  align-items: flex-start;
  margin: 0px auto !important;
  /* align-items: center; */
  padding: 0px !important;
}

.pagination-div {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account_group_btn {
  font-size: 11px !important;
  border-radius: 10px !important;
  padding: 2px 5px !important;
  cursor: pointer;
}

.styled-button {
  background-color: #3f79b5 !important;
  color: #f7f9ff !important;
  font-size: 13px !important;
  padding: 5px !important;
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around;
}

.kyc_button {
  width: 130px;
  opacity: 1;
  color: #423535 !important;
}

.sidebar-button-container {
  width: 95% !important;
  margin: auto !important;
  padding: 40px 20px;
}

.action_modal {
  width: 300px !important;
  position: absolute !important;
  top: 70px !important;
  right: 15% !important;
}

.mapped-field {
  margin: 10px;
}

.mapped-btn {
  font-size: 12px !important;
  color: white !important;
}

.mapped-fields-btn-left {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: rgb(63, 121, 181) !important;
}

.mapped-fields-btn-right {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background-color: rgb(181, 63, 124) !important;
}

.spanned-menu-text {
  position: relative;
  font-size: 12px;
  color: #fff;
  display: block;
  margin-top: -6px;
  margin-bottom: 6px;
  /* position: absolute;
  bottom: 0px;
  left: 10px;
  font-size: 11px;
  color: #fff; */
}

.spanned-menu-text-child {
  left: 10px;
}

.history-list:hover {
  background-color: rgb(235, 235, 235);
}

@media only screen and (max-width: 1300px) {
  .text-field {
    width: 400px !important;
    background-color: #f7f9ff;
    border-radius: 10px !important;
  }

  .text-field-remakrs {
    width: 400px !important;
    background-color: #f7f9ff;
    border-radius: 10px !important;
  }
}

/* qq`    111q */

.table_value {
  padding: 0 10px;
}

.canvasjs-chart-credit {
  display: none !important;
}

/* CSS for adding borders to the columns */
.chart-container .canvasjs-chart-container .canvasjs-column {
  stroke: black;
  /* Border color */
  stroke-width: 2px;
  /* Border thickness */
}

.vertical-timeline-element {
  margin: 1rem !important;
}

.custom-date-class {
  text-align: left;
  /* or "right" for right alignment */
  /* margin-top: 0.5rem; Adjust the margin as needed */
  font-size: 14px;
  /* Adjust the font size as needed */
}

.vertical-timeline::before {
  /* height: 100%; */
  background: rgb(63, 121, 181) !important;
}

.tableData {
  padding: 5px 0px !important;
}

.slick-list {
  /* padding: 16px 8px !important; */
  box-shadow: 0px 0px 6px -1px;
  border-radius: 13px;
  /* background-color: #e0e7ed; */
}

.fixed-width-card {
  width: 170px;
  /* margin: 0 8px !important; */
}

.card-body {
  padding: 16px 0px
}

.card-body .css-bhp9pd-MuiPaper-root-MuiCard-root {
  width: 90% !important;
  margin: 0px 8px !important;
  max-width: 100%;
}

.slide .slick-slide {
  width: 240px !important;
}

.accordionSummary .css-o4b71y-MuiAccordionSummary-content {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}


.modalAutocomplete input {
  height: 13px !important;
}


.css-46bh2p-MuiCardContent-root {
  padding: 11px !important;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1; /* Place it above other elements */
}


.displayHide{
display: none;
}

.commentCloseModal {
  background: #80b0d9;
  position: fixed;
  right: 0;
  top: 7%;
  width: 0;
  height: 93vh;
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation: slideOut 1s ease-out;
}

.commentOpenModal {
  background: #80b0d9;
  position: fixed;
  right: 0;
  top: 7%;
  width: 50%;
  height: 93vh;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation: slideIn 1s ease-in;
}

.pendingAccordion .MuiPaper-rounded{
  margin-top: 0px !important;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #204F7D;
  color: white;
  border-radius: 10px 10px 0 0;
}

.comment-content {
  padding: 20px;
  color: white;
}

@keyframes slideIn {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}

@keyframes slideOut {
  from {
    width: 50%;
  }
  to {
    width: 0;
  }
}

.checkbox_input input{
  display: inline-flex !important;
}

/* FlexHeaderDivider */
/* .css-t3cchj {
  background-color: rgba(240, 246, 255, 1)  !important;
  border: 2px solid rgba(195, 212, 255, 1) !important;
} */

